import Article from '../components/Article'
import Call from '../components/Call'
import Event from '../components/Event'
import Screen from '../components/Screen'
import useArticles from '../hooks/useArticles'
import useCalls from '../hooks/useCalls'
import { PageProps, Link } from 'gatsby'
import {
  getNewsDynamicRoute,
  getEventsDynamicRoute,
  getCallsDynamicRoute,
} from '../utils/routes'
import { useGetEventsHome } from '../api/useGetEvents'
import { useRef } from 'react'
import { IoChevronForward } from 'react-icons/io5'

const Home = (props: PageProps) => {
  const { articles } = useArticles(1, 5)
  const { calls } = useCalls(1, 5)
  const dateRef = useRef(new Date()) // causing infinite loop if useRef removed
  const { data } = useGetEventsHome({ date: dateRef.current })
  const upcomingEvents = data?.eventCollection.items ?? []
  const events = [...upcomingEvents]
  const sortedEvents = events.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  )
  return (
    <Screen {...props}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/5 lg:pr-16 flex items-center">
          <h1 className="h1 uppercase !text-40 xl:!text-48">
            Vítejte na oficiálních stránkách <strong>SDH Lipovka</strong>
          </h1>
        </div>
        <div className="w-full lg:w-4/5 lg:py-3 flex lg:flex-row flex-col">
          <div className="w-full lg:w-3/5 lg:py-3 lg:pr-2">
            <img
              src="trebesov_2021.jpg"
              alt="Trebesov 2021"
              className="shadow-2xl max-w-full"
            />
          </div>
          <div className="w-full lg:w-2/5 lg:py-3 flex flex-col justify-center gap-2">
            <img
              src="deti_2.jpg"
              alt="Deti exkurze u hasicu"
              className="object-cover lg:h-48 w-auto shadow-2xl max-w-full"
            />
            <img
              src="deti_1.jpg"
              alt="Deti u zakladny"
              className="object-cover lg:h-48 w-auto shadow-2xl max-w-full"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 lg:pr-16">
          <h2 className="h2-medium divider pb-4">
            <Link to={getNewsDynamicRoute(1)} className="h2-medium">
              Aktuální novinky
            </Link>
          </h2>
          {articles.map(({ title, content }, index) => (
            <Article
              title={title}
              content={content}
              isLast={index === articles.length - 1}
              key={title}
            />
          ))}
          <Link
            to={getNewsDynamicRoute(1)}
            className="text-regular mb-4 hover:underline"
          >
            Zobrazit všechny novinky
            <IoChevronForward className="relative top-[3px] left-[2px]" />
          </Link>
        </div>
        <div className="w-full lg:w-2/5">
          <h2 className="h2-medium mb-4">
            <Link
              to={getEventsDynamicRoute(1)}
              className="h2-medium divider pb-4 w-full inline-block"
            >
              Nejbližší události
            </Link>
          </h2>
          {sortedEvents.map(({ title, date }) => (
            <Event title={title} date={date} key={date} />
          ))}
          <Link
            to={getEventsDynamicRoute(1)}
            className="text-regular mb-4 hover:underline mt-6 mb-0 inline-block"
          >
            Zobrazit všechny události
            <IoChevronForward className="relative top-[3px] left-[2px]" />
          </Link>
          <div className="bg-zinc-100 lg:mt-12 mt-6 lg:-ml-6 p-6">
            <h2 className="h2-medium divider-dark pb-4 mb-4 mt-0">
              <Link to={getCallsDynamicRoute(1)} className="h2-medium">
                Nedávné výjezdy
              </Link>
            </h2>
            {calls.map(({ title, date, sys: { id }, content }, index) => (
              <Call
                title={title}
                date={date}
                key={id}
                content={content}
                isLast={index === calls.length - 1}
              />
            ))}
            <Link
              to={getCallsDynamicRoute(1)}
              className="text-regular mb-4 hover:underline mt-6 mb-0 inline-block"
            >
              Zobrazit všechny výjezdy
              <IoChevronForward className="relative top-[3px] left-[2px]" />
            </Link>
          </div>
        </div>
      </div>
    </Screen>
  )
}

export default Home
